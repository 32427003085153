<template>
  <div class="regin">
    <div class="title">招聘求职交易成功</div>
    <div class="line">
      <span class="lable">标题</span>
      <input type="text" name="" v-model="job_title" id="job_title" ref="job_title" placeholder="" />
    </div>
    <div class="line" style="display:none;">
      <span class="lable">实际成交</span>
      <input type="text" name="" v-model="price" id="price" ref="price" @blur="dealAmount" placeholder="实际成交(万元)" />
      <!--      <span>万元</span>-->
      <!-- 实际成交金额可以修改,修改后生成对应的佣金 -->
    </div>
    <div class="line">
      <span class="lable">佣金</span>
      <input v-model="brokerage" type="text" name="" placeholder="" />
      <!--      <span>元</span>-->
      <!-- 佣金可以修改 买卖 0.1%  租 10%  预留积分抵用方式-->
    </div>


    <div class="bottomBtn" @click="save()">确认已成交并支付{{brokerage}}元佣金</div>

  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import axios from "axios";
  import qs from "qs";
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        jobId: "", //房屋的id, 暂时写死, 应由Stab传递过来
        userCode: "",
        job_title:"",
        priceRegex: /^(([1-9]\d*)(\.\d{1,4})?)$|(0\.\d{1,4})$/,
        brokerage: 100 //佣金
      };
    },
    components: {

    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return	;
      }
      this.userCode = Cookies.get("kh");
      console.log(this.$route.params);
      //this.houseId = this.$route.params.houseid;
      //读出配置信息
      this.getJobDetails(); //再次读出招聘信息
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      dealAmount() {
        console.log(this.price);
        if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
          Toast('输入错误');
        }
        this.calculateBrokerage();
      },
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      async save() {
        //发起支付,支付后标记交易完成
        // if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
        //   Toast('输入错误');
        //   return;
        // }
        if (!this.brokerage || !this.priceRegex.test(this.brokerage) || this.brokerage <= 0) {
          Toast('佣金输入错误');
          return;
        }
        var that = this;
        console.log(this.houseId);
        const param = {
          id: this.houseId, //房屋id
          act:'recharge',
          type: 'job_deal_done',
          user: Cookies.get('kh'),
          sitenum: this.$global.sitenum,
          paylx: 'weixinpay',
          je: this.brokerage,
          sblx: 'h5',
          openid: Cookies.get('openid'),
          remark: '招聘求职佣金'
        };
        const url = this.$global.domain + "/horse/tools/post.ashx";
        console.log(param);
        console.log(url);
        axios.post(url, qs.stringify(param))
          .then(res => {
            console.log(res.data);
            if (res.data.status == 1) {
              const orderId = res.data.djh; //订单id
              //跳转网址
              window.location.href = "http://peng-mall.5izg.cn/horse/WXpay/jsapipay.aspx?type=house&houseid=" + that
                .houseId + "&openid=" + Cookies.get(
                  'openid') + "&djh=" + orderId + "&sitenum=" + that.$global.sitenum

              // window.location.href = "http://localhost:46277/WXpay/jsapipay.aspx?openid=" + Cookies.get(
              //   'openid') + "&djh=" + orderId;

              //


            }
          });
      },

      async getJobDetails() {
        //查询招聘
        let url = location.href;
        let id = decodeURI(getQueryVariable(url, "id"));
        var that = this;
        var uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "getJobsDetails",
          id: id, //本次查询的id
          user: Cookies.get("kh"),
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          //
          that.jobid = data[0][0];
          that.type = data[0][1];
          that.job_title = data[0][2];
          that.monthly_pay1 = data[0][3];
          that.monthly_pay2 = data[0][4];
          that.welfare_treatment = data[0][5]; //职务标签
          that.jobs_range = data[0][6];
          that.head_count = data[0][7];
          that.job_desc = data[0][8];
          that.education = data[0][9];
          that.work_years = data[0][10];
          that.work_city = data[0][11];
          that.work_place = data[0][12];
          that.tel = data[0][13];
          that.edit_time = data[0][14];
          that.num_applicants = data[0][15];
          that.num_hits = data[0][16];
          that.job_unit = data[0][17];
          that.hr_name = data[0][18];
          that.issuer_code = data[0][20]; //招聘所有者账号

          if (result.isLike == "1") {
            that.Ilike = "1";
          }


        } else {

          this.$router.push("login");
        }
      },
      calculateBrokerage() {
        if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
          this.brokerage = 0;
        } else {
          const total = this.price * 10000;
          const brokerage = total / 1000;
          this.brokerage = brokerage;
        }

      }
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #36c1bc;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
